<template>
	<div style="height:100%">
		<el-container style="height: 100%;width: 100%">
			<el-aside width="null">
				<!--导航-->
				<!--消除滚动条-->
				<!-- <el-scrollbar style="height: 100%;width: 100%"> -->
				<el-menu
					:default-active="active"
					:collapse="isCollapse"
					:unique-opened="true"
					router
					class="el-menu-vertical-demo"
					background-color="#4870ff"
					text-color="#fff"
					active-text-color="#ffd04b"
				>
					<div class="navMenu">
						<img style="margin: 30px 72px;" src="../assets/images/navLogo.png" />
						<NavMenu ref="menu" :navMenus="menuData"></NavMenu>
					</div>
				</el-menu>
				<!-- </el-scrollbar> -->
			</el-aside>
			<el-container class="contentBody">
				<el-header>
					<div style="display: inline; width: 100%">
						<div class="title-right">
							<!--							<span style="color: #4956A4;margin-right: 25px;">-->
							<!--								<i class="el-icon-phone" style="font-size: 18px; "></i>-->
							<!--								<i style="font-size: 14.5px;">400-995-1601</i>-->
							<!--							</span>-->
							<el-dropdown style="color: #4870FF;">
								<span class="el-dropdown-link">
									<i class="el-icon-user-solid" style="font-size: 16px; "></i>
									您好，{{ username }}
									<span v-if="userType == 2">医生</span>
									! 欢迎您登录
									<i class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item divided @click.native="onChangePass">
										<i class="el-icon-s-tools" style="color: #4870FF"></i>
										修改密码
									</el-dropdown-item>
									<el-dropdown-item divided @click.native="exitSystem">
										<i class="el-icon-error" style="color: #4870FF"></i>
										退出登录
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
					<Header></Header>
				</el-header>
				<BreadCrumb ref="breadCrumb"></BreadCrumb>
				<el-main class="childViewBody"><router-view /></el-main>
			</el-container>
		</el-container>
		<el-dialog title="修改密码" :lock-scroll="false" :close-on-click-modal="false" @close="onCancel" :visible.sync="showPassChange" width="500px" style="text-align: left">
			<el-form ref="passChangeForm" :rules="rules" :model="passChangeForm" label-width="90px" label-suffix=":">
				<el-form-item label="原密码" prop="password"><el-input show-password v-model="passChangeForm.password" placeholder="请输入原密码"></el-input></el-form-item>
				<el-form-item label="新密码" prop="newPassword"><el-input show-password v-model="passChangeForm.newPassword" placeholder="请输入新密码"></el-input></el-form-item>
				<el-form-item label="确认密码" prop="plainPassword">
					<el-input show-password v-model="passChangeForm.plainPassword" placeholder="请输入确认密码"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="doChangePass()">确认</el-button>
				<el-button @click="onCancel">取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import Header from './Header.vue';
import NavMenu from './NavMenu.vue';
import BreadCrumb from './Breadcrumb.vue';
import {encrypt} from "@/utils/aesUtil.js";
// import { resetRouter } from "@/router/index";

export default {
	components: {
		Header: Header,
		NavMenu: NavMenu,
		BreadCrumb: BreadCrumb
	},
	//name: 'Layout',
	props: {},
	data() {
		return {
			// 面包屑数据源
			currentPath: [],
			// 菜单是否折叠
			isCollapse: false,
			// 菜单数据源
			menuData: null,
			userType: sessionStorage.getItem('userType'),
			username: sessionStorage.getItem('username'),
			active: null,
			showPassChange: false,
			passChangeForm: {
				password: null,
				newPassword: null,
				plainPassword: null
			}
		};
	},
	created() {
		const routes = this.$router.options.routes;
		routes.forEach(item => {
			if (item.path === '/Layout') {
				this.menuData = [...item.children];
			}
		});
		this.refreshActive(this.$route);
	},
	computed: {
		rules() {
			const checkPass = (rule, value, callback) => {
				if (this.passChangeForm.newPwd && this.passChangeForm.newPwdConfirm && this.passChangeForm.newPwd !== this.passChangeForm.newPwdConfirm) {
					callback(new Error('两次密码不一致，请修改'));
				} else {
					callback();
				}
			};
			return {
				password: [{ required: true, message: '原密码不能为空', trigger: 'change' }],
				newPassword: [
					{ required: true, message: '新密码不能为空', trigger: 'change' },
					{ pattern: /^(?=!*[a-z])(?=!*[A-Z])(?=!*\d)[a-zA-Z\d]{8,}$/, message: '密码为大于八位，必须有大写字母小写字母和数字' }
				],
				plainPassword: [{ required: true, message: '确认密码不能为空', trigger: 'change' }, { validator: checkPass, trigger: ['blur', 'change'] }]
			};
		}
	},
	watch: {
		$route(route) {
			this.refreshActive(route);
		}
	},
	methods: {
		refreshActive(route) {
			// 如果有icon, 是菜单
			if (route.meta.icon) {
				this.active = route.path;
			} else {
				// 是子页面
				this.active = route.meta.menuPath;
				// 子页面取消高度自适应监听
				// window.onresize = () => {};
			}
		},
		exitSystem: function() {
			this.$confirm('您确定退出系统吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				confirmButtonClass: 'confirm-btn',
				type: 'warning',
				lockScroll: false
			})
				.then(() => {
					sessionStorage.removeItem('username');
					sessionStorage.removeItem('tRoleLists');
					sessionStorage.removeItem('token');
					sessionStorage.removeItem('routeData');
					sessionStorage.removeItem('userUuid');
					this.$router.push('/');
				})
				.catch(() => {});
		},
		onChangePass() {
			this.showPassChange = true;
		},
		doChangePass() {
			const param = Object.assign({}, this.passChangeForm);
			//delete param.newPwdConfirm;
			param.uuid = sessionStorage.getItem('userUuid');
			if(param.password){
				param.password=encrypt(param.password);
			}
			if(param.newPassword){
				param.newPassword=encrypt(param.newPassword);
			}
			if(param.plainPassword){
				param.plainPassword=encrypt(param.plainPassword);
			}
			this.$axios.post('/api/sys/web/user/encrypt/changePwd', param)
				.then(response => {
					if (response.data.code === 200) {
						this.$message.success('修改成功');
						this.onCancel();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch(e => {
					console.log(e);
					//this.$message.error('请求失败');
				});
		},
		onCancel() {
			this.showPassChange = false;
			this.$refs.passChangeForm.resetFields();
		},
		initNotifyList() {
			this.$axios
				.get('/api/pat/web/notification/list')
				.then(response => {
					if (response.data.code === 200) {
						this.notifyList = response.data.data.list;
						this.notifyCount = response.data.data.count;
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch(e => {
					console.log(e);
					this.$message.error('获取通知列表失败');
				});
		},
		toView(item) {
			let toPath;
			if (item.type === 1) {
				toPath = '/member/patient-help';
			} else {
				toPath = '/member/order-manage';
			}
			if (this.$route.path === toPath) {
				location.reload();
				return;
			}
			this.$router.push(toPath);
		}
	}
};
</script>

<style scoped>
/deep/.closebox {
	padding: 0;
	width: 30px;
	height: 30px;
	border: 0;
	float: right;
	margin: 10px 5px;
}
/deep/.el-icon-switch-button {
	font-size: 20px;
	color: #4870ff;
}
.el-dropdown-link {
	display: block;
	float: right;
	margin-right: 16px;
}
.el-header {
	background-color: #fff;
	text-align: center;
	-webkit-box-shadow: 0 0 10px 1px #e1e2e3;
	-moz-box-shadow: 0 0 10px 1px #e1e2e3;
	box-shadow: 0 0 10px 1px #e1e2e3;
	margin-bottom: 1px;
	padding: 0;
	height: 50px !important;
}

.el-main {
	color: #333;
	text-align: center;
	padding: 0;
	position: relative;
}

.el-aside {
	min-height: 858px;
}

.el-menu {
	height: 100%;
	background-color: #4870ff;
}

.el-submenu__title,
.el-menu-item {
	color: #595959;
}

.el-submenu__title i,
.el-menu-item i {
}

.el-menu-item.is-active {
	font-weight: bold;
	color: #595959;
	background-color: #eef0ff;
	border-right: #4956a4 solid 3px !important;
}

.el-menu-item.is-active i {
	color: #4956a4;
}

.el-menu:not(.el-menu--collapse) {
	width: 200px;
}

.el-aside {
	-webkit-box-shadow: 0 0 10px 1px #e1e2e3;
	-moz-box-shadow: 0 0 10px 1px #e1e2e3;
	box-shadow: 0 0 10px 1px #e1e2e3;
	min-height: unset;
	height: 100%;
}

.el-submenu .el-submenu__title:hover {
	border-right: #4956a4 solid 3px !important;
	background-color: #eef0ff !important;
}

.el-menu-item:hover {
	border-right: #4956a4 solid 3px !important;
	background-color: #eef0ff !important;
}

.contentBody {
	/*padding-left: 1%;*/
	padding-right: 0.2%;
	/* padding-top: 20px; */
}

html,
body,
#app {
	margin: 0;
	height: 100%;
	background-color: #f6f6f6;
}

.el-popover {
	width: 80px;
	min-width: 80px;
}

/* /deep/ .el-link {
    display: inline;
  } */
.head-logo {
	width: 150px;
	height: 140px;
	background-color: #4870ff;
	padding: 0 0 0 50px;
	display: flex;
	float: left;
	/*background: url("../assets/images/navLogo.png") no-repeat;*/
	/*background-size: 80px 80px;*/
	-webkit-box-shadow: 0 0 10px 1px #e1e2e3;
	-moz-box-shadow: 0 0 10px 1px #e1e2e3;
	box-shadow: 0 0 10px 1px #e1e2e3;
}

.title-right {
	text-align: right;
	float: right;
	height: 50px;
	color: #4870ff;
	font-size: 13px;
	line-height: 50px;
}
.exit-btn {
	margin-left: 2px;
	font-size: 16px;
	color: #c1c1c1;
}
.exit-btn:hover {
	color: #4956a4 !important;
}
/*.childViewBody{*/
/*  !*margin-top: -15px;*!*/
/*  width: 99%;*/
/*}*/
.el-breadcrumb {
	line-height: 40px;
}
.confirm-btn {
	background-color: #4870ff !important;
}
</style>
