<template>
	<div>
		<template v-for="navMenu in navMenus">
			<!-- 最后一级菜单 -->
			<el-menu-item
					v-if="!hasChildMenu(navMenu) && navMenu.name
						&& navMenu.meta.icon
						&& navMenu.meta.file !== 'ParentView'
						&& navMenu.query !== null"
					:key="navMenu.meta.id"
					:data="navMenu.name"
					@click="clearParam"
					:index="navMenu.meta.basePath ? navMenu.meta.basePath + '/' + navMenu.path : navMenu.path"
					:route="{path: navMenu.meta.basePath ? navMenu.meta.basePath + '/' + navMenu.path : navMenu.path, query:{getPatientDetailUuid: navMenu.query}}"
			>
				<i :class="navMenu.meta.icon"></i>
				<span slot="title">{{ navMenu.meta.title }}</span>
			</el-menu-item>
			<el-menu-item
					v-if="!hasChildMenu(navMenu) && navMenu.name
						&& navMenu.meta.icon
						&& navMenu.meta.file !== 'ParentView'
						&& navMenu.query === null"
					:key="navMenu.meta.id"
					:data="navMenu.name"
					@click="clearParam"
					:index="navMenu.meta.basePath ? navMenu.meta.basePath + '/' + navMenu.path : navMenu.path"
					:route="navMenu.meta.basePath ? navMenu.meta.basePath + '/' + navMenu.path : navMenu.path"
			>
				<i :class="navMenu.meta.icon" style="color:#ffffff;"></i>
				<span slot="title">{{ navMenu.meta.title }}</span>
			</el-menu-item>
			<!-- 此菜单下还有子菜单 -->
			<el-submenu v-if="hasChildMenu(navMenu) && navMenu.name" :key="navMenu.meta.id" :data="navMenu.name" :index="navMenu.name">
				<template slot="title">
					<i :class="navMenu.meta.icon"></i>
					<span>{{ navMenu.meta.title }}</span>
				</template>
				<!-- 递归 -->
				<NavMenu :navMenus="navMenu.children"></NavMenu>
			</el-submenu>
		</template>
	</div>
</template>

<script>
export default {
	name: 'NavMenu',
	props: ['navMenus'],
	data() {
		return {};
	},
	methods: {
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		clearParam() {
			this.$routerUtil.claerAll();
		},
		hasChildMenu(navMenu) {
			// debugger;
			let has = false;
			if (navMenu.children) {
				navMenu.children.forEach(item => {
					if (item.meta.icon) {
						has = true;
					}
				});
			}
			return has;
		}
	}
};
</script>

<style>
.navMenu {
	text-align: left;
}

/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
	display: none;
}

/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
	display: none;
}
</style>
